import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import SEO from "../components/seo";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Loader";
const StyledWrapper = styled.div`
  .main-heading {
    color: #5db9db;
    font-size: 42px;
    text-align: center;
    font-weight: 600;
    font-family: "Helvetica Neue";
    font-stretch: 100%;
    text-size-adjust: 100%;
    padding-top: 30px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
  .main-subheading {
    color: black;
    font-size: 16px;
    font-family: "Helvetica Neue", "Helvetica";
    font-weight: 600;
    margin-block-end: 20px;
    text-size-adjust: 100%;
  }
  .main-text {
    color: black;
    font-size: 16px;
    margin-block-end: 20px;
    font-family: "Helvetica Neue", "Helvetica";
    font-weight: 400;
    line-height: 27.2px;
    text-size-adjust: 100%;
  }
  ul.main-li {
    color: black;
    font-size: 16px;
    margin-block-end: 20px;
    font-family: "Helvetica Neue", "Helvetica";
    font-weight: 400;
    padding-left: 40px;
    line-height: 27.2px;
    text-size-adjust: 100%;
  }
  .content iframe {
    @media (min-width: 300px) and (max-width: 896px) {
      width: 324px;
      height: 180px;
    }
    @media (min-width: 200px) and (max-width: 280px) {
      width: 237px;
      height: 134px;
    }
    @media (min-width: 768px) and (max-width: 1180px) {
      width: 388px;
      height: 219px;
    }
  }
`;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <PrivacyPolicy />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/privacy-policy-aspire`);
};
function PrivacyPolicy() {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("privacyPolicy", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);

  return (
    <Layout>
      <StyledWrapper>
      <div className="content"> 
        <SEO title="ASPIRE PRIVACY NOTICE & USE OF COOKIES" />
        {newData === null && <Loader />}
        <div className="padding-left-8 padding-right-8">
          {newData && <h1 className="main-heading">{newData.Title1}</h1>}

          <ol>
            {newData &&
              newData.privacyPolicyAspire.length > 0 &&
              React.Children.toArray(
                newData.privacyPolicyAspire.map((item) => (
                  <li>
                    <p>
                      <strong>{item.title}</strong>
                    </p>
                    <div>
                      {item.subText && (() => {
                        const splitLines = item.subText.split('\n');
                        return splitLines.map((line, index) => {
                          const newRightLine = line.replace(/```/g, '');
                          return (
                            <div key={index}>
                              {line && <ReactMarkdown className="markdown" source={newRightLine} escapeHtml={false} />}
                            </div>
                          );
                        });
                      })()}
                    </div>
                    {/* <ReactMarkdown
                      className="padding-subtext"
                      source={item.subText}
                      escapeHtml={false}
                    ></ReactMarkdown> */}
                  
                  </li>
                ))
              )}
          </ol>
          {newData && <h1 className="main-heading">{newData.Title2}</h1>}

          <ul>
            {newData &&
              newData.GlossaryOfTerms.length > 0 &&
              React.Children.toArray(
                newData.GlossaryOfTerms.map(item => (
                  <li>
                    <p>
                      <strong>{item.title}</strong>
                    </p>
                    {/* <ReactMarkdown
                      className="padding-subtext"
                      source={item.subText}
                    ></ReactMarkdown> */}
                    <div>
                      {item.subText && (() => {
                        const splitGlossaryLines = item.subText.split('\n');
                        return splitGlossaryLines.map((line, index) => {
                          const newRightLine = line.replace(/```/g, '');
                          return (
                            <div key={index}>
                              {line && <ReactMarkdown className="markdown" source={newRightLine} escapeHtml={false} />}
                            </div>
                          );
                        });
                      })()}
                    </div>
                  </li>
                ))
              )}
          </ul>
        </div>
        </div>
      </StyledWrapper>
    </Layout>
  );
}
